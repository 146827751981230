import React from 'react';
import Layout from '../../components/commons/Layout';
import styled from 'styled-components';
import theme from '../../constants/theme';
import DynamicZone from '../../components/commons/DynamicZone';
import PageTransition from '../../components/animations/PageTransition';
import SectionHeroFullScreen from '../../components/sections/SectionHeroFullScreen';
import CasiStudioSection from '../../components/homepage/CasiStudioSection';
import { graphql } from 'gatsby';

const CasiStudioDetailWrapper = styled.div`
  display: block;
  flex-direction: column;

  .detail-header {
    display: flex;
    flex-flow: column wrap;
    min-height: 100vh;

    h2 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 36px;
      margin-bottom: 30px;
    }

    h1 {
      margin-bottom: 20px;
    }

    .detail-image {
      flex: 1;
    }
  }

  ul.tags {
    margin-bottom: 30px;
    width: 100%;
    @media (min-width: 1280px) {
      white-space: normal !important;
    }

    li {
      display: inline-block;
      font-size: 21px;
      background-color: ${theme.colorYellow};
      padding: 5px;
      @media (min-width: 1280px) {
        margin-bottom: 5px;
      }
      text-transform: uppercase;
      margin-right: 25px;
    }
  }
`;

export default function CasiStudioDetail({
  location,
  pageContext: { node, casi, seoRules },
}) {
  const { titolo, immagineDettaglio, tags, sezioniDinamiche } = node;

  return (
    <Layout
      location={location}
      seo={seoRules && seoRules[0] && seoRules[0].seo}
    >
      <CasiStudioDetailWrapper>
        <SectionHeroFullScreen
          title={titolo}
          featuredImage={immagineDettaglio}
          tags={tags}
          isDark
        />
        <DynamicZone sezioniDinamiche={sezioniDinamiche} />
        <CasiStudioSection
          casi={casi.filter((c) => c.id !== node.id && c.inSlider)}
        />
      </CasiStudioDetailWrapper>
      <PageTransition />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
